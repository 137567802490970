import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './globals.css'
import { twMerge } from 'tailwind-merge'

if (import.meta.env.DEV) {
  fetch('/api/method/parent_portal.www.parent_portal.get_context_for_dev', {
    method: 'POST',
  })
    .then((response) => response?.json() || Promise.reject())
    .then((values) => {
      const v = JSON.parse(values.message)
      //@ts-expect-error
      if (!window.frappe) window.frappe = {}
      //@ts-ignore
      window.frappe.boot = v
      // console.log("window.frappe.boot", v);
    })
}

import { initSentry } from '@/lib/sentry'
initSentry()

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
