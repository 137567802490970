import { CountryType } from '@/types/Extends/Country'
import { useFrappePostCall, useFrappePutCall } from 'frappe-react-sdk'
import { useEffect, useState } from 'react'
import { FRAPPE_APIS } from '../api.config'
import { replaceUndefinedWithNull } from '@/lib/utils/common'

export const useAcceptTerms = () => {
  const { call: submitCreate, loading } = useFrappePutCall<{
    message: any
  }>(FRAPPE_APIS.ACCEPT_TERMS.METHOD_STRING)

  const handleSubmit = async () => {
    try {
      const result = await submitCreate({
        agreed_terms_and_conditions: 1,
      })
      return Promise.resolve(result.message)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return { handleSubmit, loading }
}
