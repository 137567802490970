import { Navigate, RouteObject } from 'react-router-dom'

export const ADMIN_HOMEPAGE = "/admin/news"
export const ADMIN_ROUTES: RouteObject = {
  path: 'admin',
  lazy: () => import('./admin.layout'),
  children: [
    {
      index: true,
      element: <Navigate replace to={ADMIN_HOMEPAGE} />,
    },
    {
      path: 'news',
      // lazy: () => import("./feed/feed.page"),
      children: [
        {
          path: '',
          lazy: () => import('./news/news.page'),
        },
        {
          path: ':id',
          lazy: () => import('./news/news-detail/news-detail.page'),
        },
      ],
    },
    {
      path: 'policy-documents',
      children: [
        {
          path: '',
          lazy: () => import('./policy-documents/policy-documents.page'),
        },
        {
          path: ':id',
          lazy: () => import('./policy-documents/document-detail/document-detail.page'),
        },
      ],
      
    },
    {
      path: 'timetable',
      lazy: () => import('./timetable/timetable.page'),
    },
    {
      path: 'settings',
      lazy: () => import('./settings/settings.page'),
    },
  ],
}
