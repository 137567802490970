import { SISSettings } from '@/types/SIS/SISSettings'
import { useFrappeGetCall } from 'frappe-react-sdk'
import React, { createContext } from 'react'

interface SettingsContextProps {
  allowOverBudget?: 0 | 1
  allowRegistration?: 0 | 1
  tailorMadePrice?: number
  overBudgetDiscount?: number
}

export const SettingsContext = createContext<SISSettings>({} as SISSettings)

export const SettingsProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { data } = useFrappeGetCall<{ message: SISSettings }>(
    'parent_portal.api.login.get_settings',
  )

  return (
    <SettingsContext.Provider value={data?.message || ({} as SISSettings)}>
      {children}
    </SettingsContext.Provider>
  )
}

export const useSettingsContext = () => React.useContext(SettingsContext)
