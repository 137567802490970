import { SWRConfiguration, useFrappeGetCall } from 'frappe-react-sdk'

import { FRAPPE_APIS } from '../api.config'
import { SISCourseClass } from '@/types/SIS/SISCourseClass'
import { AttendanceCourseClassRecord } from '@/types/Extends/Attendance'
import { useEffect } from 'react'

export const useGetUserInfo = (
  skip?: boolean,
  SWRConfig?: SWRConfiguration,
) => {
  const { data, error, isLoading, isValidating, mutate } = useFrappeGetCall<{
    message: AttendanceCourseClassRecord[]
  }>(
    FRAPPE_APIS.GET_USER_INFO.METHOD_STRING,
    {},
    !skip ? `${FRAPPE_APIS.GET_USER_INFO.SWR_KEY}` : null,
    SWRConfig,
  )

  return { error, data: data?.message, isLoading, isValidating, mutate }
}
