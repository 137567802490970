import { useEffect } from 'react'

import * as Sentry from '@sentry/react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

function initSentry() {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,

    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      // 'localhost',
      'https://wisers.wellspringsaigon.edu.vn',
      // /^http:\/\/.*?\.wisers\.wellspringsaigon\.edu\.vn/,
    ],

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    ignoreErrors: [
      'NetworkError',
      'Forbidden resource',
      'NotAllowedError',
      'jwt expired',
      'Large Render Blocking Asset',
      'ResizeObserver loop completed with undelivered notifications.',
      'Importing a module script failed.',
      'Unable to preload CSS',
      'Failed to fetch dynamically imported module',
      'Failed to fetch',
    ],
  })
}

export { initSentry }
